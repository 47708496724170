const config = {
    // host: 'https://api-staging.blockchainmyart.org',
    host: 'https://api.blockchainmyart.org',
    hideDelay: 5000,
    pollingInterval: 5000,
    updateInterval: 3000,
    timeWindowMin: 1,
    timeWindowMax: 11,
    frequencyLow: 1,
    frequencyHigh: 5,
    sensitivity: 0.9,
    locale: {
        decimal: ',',
        thousands: '.',
        grouping: [3],
        currency: ['', '\u00a0€'],
    },
};
export default config;
